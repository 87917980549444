// import { ListPageRoutesResponse } from 'modules/pages/use-cases/ListPageRoutes/types';
import { PageRenderer } from 'components/page-renderer';
import { PageView } from 'modules/pages/use-cases/GetPage/types';
import { ReactElement } from 'react';
import { SWRConfig, preload } from 'swr';
import { getPage } from 'services/pages';
// import { useRouter } from 'next/router';
import DefaultLayout from 'components/layout/Default';
// import DonationPopUp from 'components/dashboard/DonationPopUp';
import Head from 'next/head';
// import NatoPopUp from 'components/dashboard/NatoPopUp';
import useService from 'hooks/useService';

export async function getStaticProps() {
  // `getStaticProps` is executed on the server side.
  const page = await getPage('/api/pages/homepage/');
  return {
    props: {
      fallback: {
        '/api/pages/homepage/': page
      }
    }
  };
}
preload('/api/pages/homepage/', getPage);

const PageContent = () => {
  const { data } = useService<PageView>(`/api/pages/homepage/`, getPage);
  if (!data) return null;
  const content = data ? JSON.parse(data!.content) : null;

  return <PageRenderer sections={content.sections} />;
};
interface HomeProps {
  fallback: any;
}
const Home = ({ fallback }: HomeProps) => {
  // const router = useRouter();
  // const [modalOpen, setModalOpen] = useState<boolean>(false);

  // const [giveUrl, setGiveUrl] = useState<string>('/give/');
  // const showNatoPopup = !!process.env.NEXT_PUBLIC_NATO_POPUP;
  // const { data: list } = useService<ListPageRoutesResponse>(
  //   `/api/pages/list/`,
  //   listPages
  // );
  // useEffect(() => {
  //   if (list && list.pages) {
  //     const defaultCampaign = list.pages.find(p => p.isCampaign && p.isDefault);
  //     if (defaultCampaign) {
  //       setGiveUrl(`/${defaultCampaign.slug}/`);
  //     }
  //   }
  // }, [list]);

  // useEffect(() => {
  //   if (!modalOpen) {
  //     setTimeout(() => setModalOpen(true), 3000);
  //   }
  //   if (window.location.href.includes('give.thereelhopeproject')) {
  //     router.push('https://www.thereelhopeproject.org/give/');
  //     return;
  //   }
  // }, []);

  return (
    <>
      <Head>
        <title>The Reel Hope Project</title>
      </Head>
      <div className="relative min-h-full">
        <SWRConfig value={{ fallback }}>
          <PageContent />
        </SWRConfig>
      </div>
      {/* {showNatoPopup ? (
        <NatoPopUp
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          rsvp={process.env.NEXT_PUBLIC_NATO_POPUP === 'rsvp'}
        />
      ) : (
        <DonationPopUp
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          giveUrl={giveUrl}
        />
      )} */}
    </>
  );
};

Home.getLayout = function getLayout(page: ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};

export default Home;
